export function JsonToCsv(data) {
    if(data.length == 0) return ''
    const headers = Object.keys(data[0])
    const rows = []

    rows.push(headers.join(';'))

    for (const row of data) {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"')
            return `"${escaped}"`
        })
        rows.push(values.join(';'))
    }

    const csvContent = rows.join('\n')
    return csvContent
}

export function Download(csvData, fileName, fileType) {
    const blob = new Blob([csvData], { type: fileType })

    const downloadLink = document.createElement('a')
    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = fileName

    document.body.appendChild(downloadLink)

    downloadLink.click()

    document.body.removeChild(downloadLink)
}

export const DEFAULT_DEBOUNCE_DELAY = 500
export function debounce(func, delay = DEFAULT_DEBOUNCE_DELAY) {
    let timerId;

    return function (...args) {
        const context = this;
    
        clearTimeout(timerId);
        timerId = setTimeout(function () {
            func.apply(context, args);
        }, delay);
    };
}